import './App.scss';
import { NavLink } from 'react-router-dom';
import { ReactComponent as RightArrow } from './right-arrow.svg';

function Nav() {
  return (
    <nav className="container">
        <ul>
            <li>
              <NavLink to="/" exact activeClassName="active">Home</NavLink>
            </li>
            <li><NavLink to="/about" activeClassName="active">More about me</NavLink></li>
            <li><a href="https://drive.google.com/file/d/1_DfLAFQNqTMRtY0WVe-XJWXIF7Mo1K-s/view?usp=sharing" target="_blank">Resume <RightArrow className="arrow-tilt"/></a></li>
        </ul>
    </nav>
  );
}

export default Nav;
