import './App.scss';
import { useRef, useEffect } from 'react';
import { ReactComponent as RightArrow } from './right-arrow.svg';
import { gsap } from 'gsap';

function About() {

  let line1 = useRef(null);
  let line2= useRef(null);

  useEffect(() => {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    gsap.from([line1, line2], 0.8, {
      delay: 0.2,
      ease: 'power3.out',
      y: 85,
      stagger: {
        amount: .15
      }

    });

  }, [line1, line2])

  return (
    <div className="about-section">
      <div className="hero-section container">
        <div className="content">
          <h1>Hi, I'm Astha</h1>
          <div className="paragraph-content-mobile">
            <p>I am a Product Designer with a background in technology. I enjoy solving problems with end to end product creation.</p>
          </div>
          <div className="paragraph-content">
            <div className="line-wrap-p">
              <span ref={el => (line1 = el)}>I am a Product Designer with a background in technology. I enjoy</span>
            </div>
            <div className="line-wrap-p">
              <span ref={el => (line2 = el)}>solving problems with end to end product creation.</span>
            </div>
          </div>
        </div>
      </div>
      <div className="about-content container">
        <div className="image">
          <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145599/astha-agarwal/about-image_seocml.webp" alt="about-image" border="0" />
        </div>
        <div className="content">
          <p>With more than 6 years of work experience, I have a strong ability to make complex interfaces simple. My expertise includes UX research, ideating through concepts, and delivering impactful, solid design experiences. I have worked with teams from around the world, and for organizations ranging from health care and sports, to travel.
            <br /><br />I'm also part of the <a href="https://www.toptal.com/designers/resume/astha-agarwal" target="_blank">Toptal</a> designers community and am a co host for the Mumbai chapter for <a href="http://hexagonux.com/mumbai" target="_blank">Hexagon UX</a>, a San Francisco based non profit community that empowers women and non-binary folks in UX.</p>
        </div>
      </div>
      <div className="section container">
        <h3>My education</h3>
        <p>As an undergrad, I studied electronics engineering at <b>Mumbai University</b>. After stumbling into design and getting some work experience in the field, I went on to earn a Masters in Interaction Design from the <b>Monash University</b>, Melbourne.</p>
      </div>
      <div className="section container">
        <h3>Professional experience</h3>
        <ul>
          <li><span>Intermiles <span className="date"> |  2020-2021</span></span>Senior Product Designer</li>
          <li><span>MakeMyTrip <span className="date"> |  2020 -2021</span></span>Senior Product Designer - Contract</li>
          <li><span>StyleCracker <span className="date"> |  2019 -2020</span></span>Senior Product Designer - Contract</li>
          <li><span>UpGrad <span className="date"> |  2019 -2019</span></span>Senior Product Designer - Contract</li>
          <li><span>GuestTouch <span className="date"> |  2019 -2019</span></span>Senior Product Designer - Contract</li>
          <li><span>CASA Forte <span className="date"> |  2017 -2019</span></span>Senior Product Designer</li>
          <li><span>Practo <span className="date"> |  2015 -2016</span></span>Product Designer</li>
          <li><span>Genii <span className="date"> |  2015 -2015</span></span>Product Designer</li>
        </ul>
        <a className="resume-link" href="https://drive.google.com/file/d/1_DfLAFQNqTMRtY0WVe-XJWXIF7Mo1K-s/view?usp=sharing" target="_blank">View Full Resume <span className="icon"><RightArrow /></span></a>
      </div>
      <div className="section container fun-section">
        <h3>What I do for fun</h3>
        <p>In my spare time, you'll find me either doing hand lettering, calligraphy or just walking outdoors. Travelling has always been a huge source of inspiration for me. From meeting new people to exploring exotic foods and diverse cultures, it has opened my mind to different perspectives and has made me more empathetic and curious as a designer. Travelling helps me design user experiences that tell compelling stories and resonate with the end user.</p>
        <ul className="photos">
          <li><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620147714/astha-agarwal/Screenshot_2021-03-17_at_12.27.08_PM_u5kbbt.webp" alt=""/></li>
          <li><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620147713/astha-agarwal/Screenshot_2021-03-17_at_12.27.51_PM_ajdu5p.webp" alt=""/></li>
          <li><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620147714/astha-agarwal/Screenshot_2021-03-17_at_12.28.07_PM_lgkap4.webp" alt=""/></li>
          <li><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620147714/astha-agarwal/Screenshot_2021-03-17_at_12.29.11_PM_ljkcxg.webp" alt=""/></li>
          <li><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620147715/astha-agarwal/Group_87_wds1pw.webp" alt=""/></li>
          <li><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620147714/astha-agarwal/Group_82_likazt.webp" alt=""/></li>
        </ul>
      </div>
    </div>
  );
}

export default About;
