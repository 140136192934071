import './App.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as RightArrow } from './right-arrow.svg';
import { ReactComponent as SCLogo } from './stylecracker-logo.svg';
import { ReactComponent as MMTLogo } from './mmt-logo.svg';
import { ReactComponent as UpgradLogo } from './upgrad-logo.svg';
import { ReactComponent as PractoLogo } from './practo-logo.svg';
import { ReactComponent as IntermilesLogo } from './intermiles-logo.svg';

import { ReactComponent as SCLogoMobile } from './sc-mobile.svg';
import { ReactComponent as MMTLogoMobile } from './mmt-mobile.svg';
import { ReactComponent as UpgradLogoMobile } from './upgrad-mobile.svg';
import { ReactComponent as PractoLogoMobile } from './practo-mobile.svg';
import { ReactComponent as IntermilesLogoMobile } from './intermiles-mobile.svg';
import LocomotiveScroll from 'locomotive-scroll';

import { useRef, useEffect } from 'react';
import Fade from 'react-reveal/Fade'; // Importing Zoom effect
import { gsap } from 'gsap';
import Lottie from 'react-lottie';
import animationData from './lottie-animations/sign.json';

function Home() {

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  let line1 = useRef(null);
  let line2= useRef(null);
  let line3 = useRef(null);
  let line4 = useRef(null);
  let line5 = useRef(null);
  let arrow = useRef(null);
  let image = useRef(null);
  let brands = useRef(null);
  // let header = useRef(null);

  useEffect(() => {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    document.body.style.opacity = 1;

    let header = document.getElementsByTagName('header');
  
    gsap.from([image], 2, {
      delay: 0,
      ease: 'none',
      opacity: 0
    });

    gsap.from([line1, line2, line3, line4, line5], 1.2, {
      delay: 0.5,
      ease: 'power3.out',
      y: 85,
      stagger: {
        amount: .3
      }

    });

    gsap.from(header, 1.2, {
      delay: 1,
      ease: 'power3.out',
      y: -50,
      opacity: 0
    });

    gsap.from(brands, 1.2, {
      delay: 1,
      ease: 'power3.out',
      y: 100,
      opacity: 0
    });

    // const scroll = new LocomotiveScroll({
    //   el: document.querySelector('[data-scroll-container]'),
    //   smooth: true
    // });

  }, [])

  return (
    <div className="home">
      <div className="hero-section container">
        <div className="content" data-scroll data-scroll-speed="1">
          <div className="line-wrap">
            <h1 ref={el => (line1 = el)}>Hi, I'm Astha</h1>
          </div>
          <div className="paragraph-content">
            <div className="line-wrap-p">
              <span ref={el => (line2 = el)}>I am a Product Designer who loves</span>
            </div>
            <div className="line-wrap-p">
              <span ref={el => (line3 = el)}>connecting business goals with</span>
            </div>
            <div className="line-wrap-p">
              <span ref={el => (line4 = el)}>user experience.</span>
            </div>
          </div>
          <div className="line-wrap-link">
            <Link to="/about" ref={el => (line5 = el)}>Get to know me <span className="icon"><RightArrow/></span></Link>
          </div>
        </div>
        <div className="image-container" ref={el => (image = el)} data-scroll data-scroll-speed="3">
          <div className="image">
            <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145538/astha-agarwal/hero-image_gyctpa.webp" alt="hero-image" border="0" />
            <div className="sign-container">
            {/* <Lottie 
            options={defaultOptions}
              height={400}
              width={400}
            /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="brands-container container"  ref={el => (brands = el)}>
        <div className="brands">
          <h3>Fortunate to have worked with</h3>
          <div className="logo-container">
            <ul className="logos">
              <li><a href="https://www.intermiles.com/" target="_blank"><IntermilesLogo /></a></li>
              <li><a href="https://www.upgrad.com/"  target="_blank"><UpgradLogo /></a></li>
              <li><a href="https://www.makemytrip.com/" target="_blank"><MMTLogo /></a></li>
              <li><a href="http://practo.com/" target="_blank"><PractoLogo /></a></li>
              <li><a href="https://www.stylecracker.com/" target="_blank"><SCLogo /></a></li>
            </ul>
          </div>
          <div className="logo-container-mobile">
            <ul>
              <li><a href="https://www.intermiles.com/" target="_blank"><IntermilesLogoMobile /></a></li>
              <li><a href="https://www.makemytrip.com/" target="_blank"><MMTLogoMobile /></a></li>
              <li><a href="http://practo.com/" target="_blank"><PractoLogoMobile /></a></li>
              <li><a href="https://www.stylecracker.com/" target="_blank"><SCLogoMobile /></a></li>
              <li><a href="https://www.upgrad.com/"  target="_blank"><UpgradLogoMobile /></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="work-container container">
        <h3>Some selected work</h3>
        {/* <Fade bottom duration={2000} distance="30px"> */}
          <ul className="works">
          <li>
              <a href="https://astha-agarwal.medium.com/redesigning-stylecracker-15696c8f4d94" target="_blank">
                <div className="image">
                  {/* <video width="100%" autoPlay src="https://res.cloudinary.com/dgksx9vlc/video/upload/v1620296160/Comp_1_1_mkja9a.mp4" type="video/mp4" autoPlay loop muted></video> */}
                  <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145542/astha-agarwal/sc-image_ctq2q4.webp" alt="sc-image" border="0" />
                </div>
                <div className="content">
                  <h4>Stylecracker</h4>
                  <p>Redesigning India’s first online personal styling platform.</p>
                  <span className="link">Read Case Study <span className="icon"><RightArrow ref={el => (arrow = el)} /></span></span>
                </div>
              </a>
          </li>
          <li>
            
              <a href="https://www.toptal.com/designers/ux/loyalty-program-design-framework" target="_blank">
                <div className="image">
                  {/* <video width="100%" autoPlay src="https://res.cloudinary.com/dgksx9vlc/video/upload/v1620301618/intermiles-showreel_wn3lba.mp4" type="video/mp4" autoPlay loop muted></video> */}
                  <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145538/astha-agarwal/intermiles-image_vesjdw.webp" alt="intermiles-image" border="0" />
                </div>
                <div className="content">
                  <h4>Intermiles</h4>
                  <p>Give Them Incentive – How to Leverage a Loyalty Program Design Framework</p>
                  <span className="link">Read Case Study <span className="icon"><RightArrow ref={el => (arrow = el)} /></span></span>
                </div>
              </a>
          </li>
          <li>
            
            <a href="https://astha-agarwal.medium.com/redesigning-makemytrips-multicity-journey-f7235cd5dc18" target="_blank">
              <div className="image">
              <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145539/astha-agarwal/mmt-image_cyh0jh.webp" alt="mmt-image" border="0" />
              </div>
              <div className="content">
                <h4>MakeMyTrip</h4>
                <p>Redesigning India’s first online personal styling platform.</p>
                <span className="link">Read Case Study <span className="icon"><RightArrow ref={el => (arrow = el)} /></span></span>
              </div>
            </a>
          </li>
          <li>
            
            <a href="https://www.behance.net/gallery/68219473/Practo-Consult-Study" target="_blank">
              <div className="image">
              <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145541/astha-agarwal/practo-image_igrsxb.webp" alt="practo-image" border="0" />
              </div>
              <div className="content">
                <h4>Practo</h4>
                <p>Designing a doctor platform for an online consultation</p>
                <span className="link">Read Case Study <span className="icon"><RightArrow ref={el => (arrow = el)} /></span></span>
              </div>
            </a>
          </li>
          <li>
            
            <a href="https://www.behance.net/gallery/52148431/Jupo-Services" target="_blank">
              <div className="image">
              <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145538/astha-agarwal/jupo-image_h9iows.webp" alt="jupo-image" border="0" />
              </div>
              <div className="content">
                <h4>Jupo</h4>
                <p>Android app that connects people to the best handymen and handymen to respective jobs.</p>
                <span className="link">Read Case Study <span className="icon"><RightArrow ref={el => (arrow = el)} /></span></span>
              </div>
            </a>
          </li>
          <li>
            
            <a href="https://www.behance.net/gallery/68613567/Kiddle-Card-game-to-learn-kindness" target="_blank">
              <div className="image">
              <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145538/astha-agarwal/kiddle-image_ozd71i.webp" alt="kiddle-image" border="0" />
              </div>
              <div className="content">
                <h4>Kiddle</h4>
                <p>A card game for kids to learn about kindness.</p>
                <span className="link">Read Case Study <span className="icon"><RightArrow ref={el => (arrow = el)} /></span></span>
              </div>
            </a>
          </li>
        </ul>
        {/* </Fade> */}
      </div>
      <div className="testimonial-container">
        <div className="container">
          <h3>Some kind words</h3>
        </div>
        <div className="testimonial-carousel">
          <ul className="testimonials">
          <li>
              <div className="content">
                <p>“A <b>perfect user advocate</b>. She is good at project management and organization skills. On the go, quick and deep in to the project with her questions is what makes her best in the field..."</p>
              </div>
              <div className="person">
                <div className="image">
                  <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1621322565/a_vfpfog.webp" alt="kapil" border="0" />
                </div>
                <div className="info">
                  <p className="name">Kapil Solanki</p>
                  <p className="company">Associate Director User Experience</p>
                  <p className="position">Reported to him at MakeMyTrip</p>
                </div>
              </div>
            </li>
            <li>
              <div className="content">
                <p>“She is able to quickly learn processes, add value, coordinate work flow and manage other people's work tasks. She can break apart large tasks into smaller solvable components..."</p>
              </div>
              <div className="person">
                <div className="image">
                  <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145537/astha-agarwal/andy_oghqaj.webp" alt="andy" border="0" />
                </div>
                <div className="info">
                  <p className="name">Andy McCabe</p>
                  <p className="company">CTO Teamor (Melbourne)</p>
                  <p className="position">Reported to him at CasaForte</p>
                </div>
              </div>
            </li>
            <li>
              <div className="content">
                <p>“She pays attention to details and asks the right questions that help you uncover critical use-cases which improve overall product and user experience…"</p>
              </div>
              <div className="person">
                <div className="image">
                  <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1620145538/astha-agarwal/pinak_eijzkj.webp" alt="pinak" border="0" />
                </div>
                <div className="info">
                  <p className="name">Pinak Soni</p>
                  <p className="company">CEO GuestTouch (San Fransisco)</p>
                  <p className="position">My Client</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Home;
