import './App.scss';
import About from './About';
import Home from './Home';
import Nav from './Nav';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { ReactComponent as Linkedin } from './linkedin.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Medium } from './medium.svg';
import LocomotiveScroll from 'locomotive-scroll';
import { useEffect } from 'react';

function App() {

  return (
    <Router>
      <div className="App" data-scroll-container>
        <header>
          <Nav/>
        </header>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
        </Switch>
        <div className="footer">
          <div className="container">
            <p>You can reach out to me at</p>
            <h1 className="email"><a href="mailto:astha.a.agarwal@gmail.com">astha.a.agarwal@gmail.com</a></h1>
            <p>I am always up for a cup of coffee :)</p>
            <ul className="social-links">
              <li><a href="https://www.linkedin.com/in/astha-agarwal/" target="_blank"><Linkedin /></a></li>
              <li><a href="https://www.instagram.com/asthaagarwal24/" target="_blank"><Instagram /></a></li>
              <li>
                  <a href="mailto:astha.a.agarwal@gmail.com"><Mail /></a>
              </li>
              <li><a href="https://astha-agarwal.medium.com/" target="_blank"><Medium /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
